"use client"
import { useState, useRef, useEffect } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { connect } from "react-redux";
import actions from "../redux/actions/AuthActions";
import { getDictionary, getField } from "../utils/translation";
import { notify } from "../utils/notify";
import "react-toastify/dist/ReactToastify.css";
import SignInUpPhoneInput from "../components/SignInUpPhoneInput/SignInUpPhoneInput";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Loader from "../components/Loader/Loader";
import LanguagesPopup from "../components/Home/LanguagesPopup/LanguagesPopup";
import { getSvg } from "../utils/getSvg";
import { enableScroll, disableScroll, getTitleCase } from "../Common";
import "./login.css";
import '../globals.css'
const Login = ({
    translation,
    language,
    supportedLanguages,
    loginLoading,
    login,
    selectedTheme,
    getDesignsLoading,
    loginDesignSetup,
    mainDesign,
    setLoginSkipped,
    selectedRestaurant,
    appSource,
    dineInSelectedRestaurant,
    dineInSelectedBranch,
    dineInSelectedTable,
    paySelectedRestaurant,
    paySelectedBranch,
    paySelectedTable,
    QRSelectedRestaurant,
    QRSelectedBranch,
    selectedRestaurantSlug
}) => {
    const dictionary = getDictionary(translation, "login-page");
    const router = useRouter()
    const searchParams = useSearchParams();
    const from = searchParams.get('from');
    const decodedFrom = from ? decodeURIComponent(from) : "/";
    const media = searchParams.get('media');
    // const from = router?.state?.from ? router?.state?.from : "/";

    const phoneRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phone, setPhone] = useState({
        phone: "",
        country: "",
        dialCode: "",
    });
    const [phoneError, setPhoneError] = useState(
        getField(dictionary, "required", language)
    );
    const [openLanguagesPopup, setOpenLanguagesPopup] = useState(false);
    const [mainDesignIconsSytle, setMainDesignIconsStyle] = useState("FullColor");

    const handleLogin = (fullPhone) => {
        login(
            fullPhone,
            () => {
                const phoneString = JSON.stringify(phone);
                if (phone?.country === "ae") {
                    router.push(`/verify-otp${decodedFrom ? `?from=${decodedFrom}` : ''}`, {
                        query: {
                            phone: fullPhone,
                            phoneObject: phoneString,
                        },
                    });
                } else {
                    router.push(`/get-otp?phone=${phoneString}${decodedFrom ? `&from=${decodedFrom}` : ''}`);
                }
            },
            // On account not verified
            () => {
                const phoneString = JSON.stringify(phone);
                if (phone?.country === "ae") {
                    router.push(`/verify-otp${decodedFrom ? `?from=${decodedFrom}` : ''}`, {
                        query: {
                            phone: fullPhone,
                            phoneObject: phoneString,
                        },
                    });
                } else {
                    router.push(`/get-otp?phone=${phoneString}${decodedFrom ? `&from=${decodedFrom}` : ''}`);
                }
            },
            // On account not found
            () => {
                const phoneString = JSON.stringify(phone);
                router.push(`/signup?phone=${phoneString}${from ? `&from=${from}` : ''}`);
            },
            // On error
            (error) => {
                console.error(error);
            }
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        if (phoneError) {
            return;
        }
        //else, send the login request
        const fullPhone = `+${phone.phone}`;
        handleLogin(fullPhone);
    };

    const handleSkipLogin = () => {
        const mainUrl = appSource === "dine-in"
            ? `/dine-in/${dineInSelectedRestaurant}/${dineInSelectedBranch}/${dineInSelectedTable}`
            : appSource === "pay"
                ? `/dine-in/${paySelectedRestaurant}/${paySelectedBranch}/${paySelectedTable}`
                : appSource === "QR"
                    ? `/qr/${QRSelectedRestaurant}/${QRSelectedBranch}`
                    : `/${selectedRestaurantSlug}/home`

        // setLoginSkipped(true);
        router.push(
            media
                ? decodeURIComponent(from)
                : router?.state?.productDetails
                    ? `${from}`
                    : from && from?.split('/')?.filter(Boolean)?.pop() == "checkout"
                        ? `/${mainUrl}/Cart`
                        : appSource === "QR"
                            ? `${mainUrl}`
                            : appSource === "dine-in" || appSource === "pay"
                                ? -2
                                : // ? `/dine-in/${dineInSelectedRestaurant}/${dineInSelectedBranch}/${dineInSelectedTable}/home`
                                // : appSource === "pay"
                                // ? `/pay/${paySelectedRestaurant}/${paySelectedBranch}/${paySelectedTable}/home`
                                `/${selectedRestaurantSlug ? selectedRestaurantSlug : ""}/home`
        );
    };

    const handleChooseLanguageClick = () => {
        setOpenLanguagesPopup(true);
    }

    useEffect(() => {
        if (
            openLanguagesPopup
        ) {
            disableScroll();
        } else {
            enableScroll();
        }

        return () => {
            enableScroll();
        };
    }, [openLanguagesPopup]);

    useEffect(() => {
        if (mainDesign) {
            let mainDesignSetup;
            if (mainDesign?.designSetup?.length === 2 && selectedTheme && selectedTheme === "dark-mode") {
                mainDesignSetup = mainDesign?.designSetup[1];
            } else {
                mainDesignSetup = mainDesign?.designSetup[0];
            }
            const iconsStyle = mainDesignSetup?.iconsStyle;
            if (iconsStyle === "coloredIcons") {
                setMainDesignIconsStyle("Colored")
            } else if (iconsStyle === "fullColorIcons") {
                setMainDesignIconsStyle("FullColor");
            } else if (iconsStyle === "linearIcons") {
                setMainDesignIconsStyle("Linear");
            } else if (iconsStyle === "minimalistIcons") {
                setMainDesignIconsStyle("Minimalist");
            }
        }
    }, [mainDesign])

    if (getDesignsLoading) {
        return <Loader darkBg={true} />
    }
    return (
        <div className={`login-page-wrapper login-bg-image ${language === "arabic" ? "rtl" : ""}`}>
            {openLanguagesPopup && <LanguagesPopup
                close={() => {
                    setOpenLanguagesPopup(false);
                }}
                isMainDesign={true}
            />
            }
            {/* <SignupAdditionalInfoPopup /> */}
            {/* commneted loader out temporarly, until we add reset all loaders function to prevent it from loading forever */}
            {/* {loginLoading && <Loader darkBg={true} />} */}
            <div className="login-page-container">
                <div className="login-skip-container">
                    <div className="login-skip-link" onClick={handleSkipLogin}>
                        <div className="login-skip-text">
                            {getField(dictionary, "skip-text", language)}
                        </div>
                        <ArrowForwardIcon className="login-skip-arrow" />
                    </div>
                </div>
                <div className="login-header">
                    <div className="login-title">
                        {getField(dictionary, "page-title", language)}
                    </div>
                    <div className="login-subtitle">
                        {getField(dictionary, "page-subtitle", language)}
                    </div>
                </div>
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-form-input-wrapper" ref={phoneRef}>
                        <SignInUpPhoneInput
                            phone={phone}
                            setPhone={setPhone}
                            placeholder={getField(
                                dictionary,
                                "phone-number-placeholder",
                                language
                            )}
                            isSubmitting={isSubmitting}
                            phoneError={phoneError}
                            setPhoneError={setPhoneError}
                        />
                    </div>
                    <button type="submit" className="login-next-btn">
                        {getField(dictionary, "next-btn", language)}
                    </button>
                </form>
                {/* <div className="login-other-logins">
          <div className="login-other-text">or Login With</div>
          <div className="login-other-media-icons-container">
            <div className="login-other-media-icon-container">
              <img src={FACEBOOK} alt="facebook" />
            </div>
            <div className="login-other-media-icon-container">
              <img src={GOOGLE} alt="google" className="login-google-img" />
            </div>
            <div className="login-other-media-icon-container">
              <img src={TWITTER} alt="twitter" />
            </div>
          </div>
        </div>
        <div className="login-footer">
          <div className="login-footer-create-account-container">
            <div className="login-footer-create-account-text">
              Don't have an Account?
            </div>
            <Link to="/signup" className="login-create-account-link">
              Create One
            </Link>
          </div>
        </div> */}
            {supportedLanguages && supportedLanguages?.length > 1 && (
                <footer>
                    <div className="login-translation-btn-container"
                        onClick={handleChooseLanguageClick}
                    >
                        <div className="login-translation-icon-container">
                            {getSvg(`languageIcon${mainDesignIconsSytle}`, { color: "var(--text-color-over-overlay-main-design" }, { width: "25px", height: "25px" })}
                        </div>
                        <div>{getTitleCase(getField(dictionary, "choose-language", language))}</div>
                    </div>
                    </footer>    
            )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    selectedRestaurantSlug: state.restaurants.selectedRestaurantSlug,
    language: state.language.language,
    supportedLanguages: state.translation.supportedLanguages,
    getDesignsLoading: state.designs.getDesignsLoading,
    loginDesignSetup: state.designs.loginDesignSetup,
    mainDesign: state.designs.mainDesign,
    selectedTheme: state.designs.selectedTheme,
    loginLoading: state?.auth?.loginLoading,
    appSource: state.appSource.appSource,
    selectedRestaurant: state.restaurants.selectedRestaurant,
    dineInSelectedRestaurant: state.restaurants.dineInSelectedRestaurant,
    dineInSelectedBranch: state.restaurants.dineInSelectedBranch,
    dineInSelectedTable: state.restaurants.dineInSelectedTable,
    paySelectedRestaurant: state.restaurants.paySelectedRestaurant,
    paySelectedBranch: state.restaurants.paySelectedBranch,
    paySelectedTable: state.restaurants.paySelectedTable,
    QRSelectedRestaurant: state.restaurants.QRSelectedRestaurant,
    QRSelectedBranch: state.restaurants.QRSelectedBranch
});

const mapDispatchToProps = (dispatch) => ({
    login: (phone, onSuccess, onAccountNotVerified, onAccountNotFound, onError) =>
        dispatch(
            actions.login(
                phone,
                onSuccess,
                onAccountNotVerified,
                onAccountNotFound,
                onError
            )
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
