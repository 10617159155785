import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { actions } from "../../../redux/actions/LanguageActions";
import { getDictionary, getField } from "../../../utils/translation";
import { getTitleCase } from "../../../Common";
import { getSvg } from "../../../utils/getSvg";
import './LanguagesPopup.css';

const LanguagesPopup = ({
    translation,
    language,
    supportedLanguages,
    setLanguage,
    close,
    isMainDesign = false
}) => {
    const dictionary = getDictionary(translation, "select-language-popup");
    const containerRef = useRef(null);
    const [selectedLanguage, setSelectedLanguage] = useState(language);

    // const handleSelectLanguage = (lang) => {
    //     setSelectedLanguage(lang.toLowerCase());
    //     setLanguage(lang);
    //     close();
    // }
    const handleSelectLanguage = (lang) => {
        setSelectedLanguage(lang?.name?.toLowerCase());
        setLanguage(lang?.name);
        close();
    }

    const handleCancel = () => {
        close();
    }

    const handleOutsideClick = (event) => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            close();
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [handleOutsideClick]);

    return (
        <div className={`languages-popup-page ${language === "arabic" ? "rtl" : ""} ${isMainDesign ? "main-design" : ""}`}>
            <div className="languages-popup-container" ref={containerRef}>
                <div className="languages-popup-content-container custom-scrollbar">
                    <div className="languages-popup-header">
                        {getSvg("languageSelectionImage", { color: isMainDesign ? "var(--main-color-main-design)" : "var(--main-color)", color2: isMainDesign ? "var(--main-color-warm-main-design)" : "var(--main-color-warm" })}
                        <div className="languages-popup-title">
                            {getField(dictionary, "title", language)}
                        </div>
                    </div>
                    <div className="languages-popup-list-container">
                        <ul>
                            {supportedLanguages?.length > 0 && supportedLanguages?.map((lang, index) => {
                                return (
                                    <li key={index} onClick={() => handleSelectLanguage(lang)} className={selectedLanguage?.name === lang?.name?.toLowerCase() ? 'selected-language' : ''}>
                                        {getTitleCase(getField(dictionary, lang?.name?.toLowerCase(), language))}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="languages-popup-btns-container">
                        <button className="languages-popup-close-btn close-btn" onClick={handleCancel}>
                            {getField(dictionary, "close-btn", language)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.language.language,
    supportedLanguages: state.translation.supportedLanguages,
});

const mapDispatchToProps = (dispatch) => ({
    setLanguage: (value) =>
        dispatch(actions.setLanguage(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesPopup);
